//Type
import { AuthStateAppProps } from '@interfaces/auth/AuthStateApp';

//Library
import React, { ReactElement, useEffect, useState } from 'react';
import { Amplify, Auth, Hub } from 'aws-amplify';
import {
    AuthState,
    CognitoUserInterface,
    UI_AUTH_CHANNEL,
    AUTH_STATE_CHANGE_EVENT,
    onAuthUIStateChange
} from '@aws-amplify/ui-components';
import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
import Head from 'next/head';

//Amplifyt Config
import appsync from '@components/graphql/config/awsAppsync';
import authConfig from '@components/auth/config/awsCognito';

Amplify.configure({ ...appsync, Auth: authConfig, ssr: true });

//code
import Loader from '@components/layout/Loader';
import SignInForm from '@components/auth/helpers/SignInForm';
import ForgotPasswordForms from '@components/auth/helpers/ForgotPasswordForms';
import Maintenance from '@components/auth/helpers/Maintenance';
import Redirect from '@components/auth/helpers/Redirect';

/**
 * Amplify auth module.
 *
 * @param {AuthStateAppProps} props - The children to show if the user is signed in.
 * @returns {ReactElement} - The login funnel or the children that we pass
 */
const AuthStateApp: React.FC<AuthStateAppProps> = ({
    children
}: AuthStateAppProps): ReactElement => {
    const [authState, setAuthState] = useState<AuthState | undefined>();
    const [authData, setAuthData] = useState<CognitoUserInterface | undefined>();
    const [loading, setLoading] = useState<boolean>(true);

    /**
     * Change the sate of amplify to pass between screen.
     *
     * @param {AuthState} nextAuthState - The next state
     * @param {CognitoUserInterface} authData - The user data
     */
    const handleAuthStateChange = (nextAuthState: AuthState, authData?: CognitoUserInterface) => {
        Hub.dispatch(UI_AUTH_CHANNEL, {
            event: AUTH_STATE_CHANGE_EVENT,
            message: nextAuthState,
            data: authData ? authData.user : null
        });
    };

    useEffect(() => {
        let mounted = true; // Indicate the mount state

        /**
         * Get the user session from Auth.currentAuthenticatedUser. We double down here because if
         * not we have a problem when we open a new page on the navigator.
         */
        const getUserSession = async () => {
            try {
                setLoading(true);
                const user = await Auth.currentAuthenticatedUser();
                handleAuthStateChange(AuthState.SignedIn, user);
                setLoading(false);
            } catch {
                setLoading(false);
                handleAuthStateChange(AuthState.SignIn);
            }
        };

        //get the manually session because sometime amplify don't fire it on refresh.
        getUserSession();

        onAuthUIStateChange((nextAuthState, authData) => {
            if (mounted) {
                setAuthState(nextAuthState);
                setAuthData(authData as CognitoUserInterface);
            }
        });

        return () => {
            mounted = false;
        };
    }, []);
    // Block everything with maintenance component if environment is set to maintenance.
    if (process.env.NEXT_PUBLIC_MAINTENANCE && JSON.parse(process.env.NEXT_PUBLIC_MAINTENANCE)) {
        return <Maintenance />;
    }

    return (
        <>
            {/* Wrap children with Redirect if authData is available */}
            {authData && (
                <Redirect authData={authData} onClick={() => setLoading(true)}>
                    {children}
                </Redirect>
            )}
            {/* Login if no authData available */}
            {Boolean(!authData) && Boolean(!loading) && (
                <>
                    <Head>
                        <title>Solar AI</title>
                        <link rel="icon" href="/favicon.ico" />
                    </Head>
                    <AmplifyAuthenticator initialAuthState={AuthState.SignIn}>
                        <div
                            slot="loading"
                            className="h-screen w-view flex justify-center items-center">
                            <Loader />
                        </div>
                        <SignInForm
                            authState={authState}
                            authData={authData}
                            handleAuthStateChange={handleAuthStateChange}
                        />
                        <ForgotPasswordForms
                            authState={authState}
                            authData={authData}
                            handleAuthStateChange={handleAuthStateChange}
                        />{' '}
                    </AmplifyAuthenticator>
                </>
            )}
            {/* Loading screen */}
            {Boolean(loading) && (
                <div
                    className="h-screen w-view flex justify-center items-center"
                    aria-label="loading">
                    <Loader />
                </div>
            )}
        </>
    );
};

export default AuthStateApp;
