//library
import React, { ReactElement } from 'react';
import type { AppProps /*, AppContext */ } from 'next/app';
import * as Sentry from '@sentry/node';

//css
import '../styles/tailwind.css';

//code
import AuthStateApp from '@components/auth/AuthStateApp';
import useProgressBar from '@components/helpers/useProgressBar';
import useAnalytics from '@components/helpers/useAnalytics';

//Sentry
/** Follow: https://leerob.io/blog/configuring-sentry-for-nextjs-apps */
if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    Sentry.init({
        enabled: process.env.NODE_ENV === 'production',
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
        normalizeDepth: 100
    });
}

/**
 * The app function.
 *
 * @param props - The props of the page.
 * @returns {ReactElement} - The page we want to navigate.
 */
function MyApp(props: AppProps): ReactElement {
    const { Component, pageProps } = props;
    // Sentry: Workaround for https://github.com/vercel/next.js/issues/8592
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const err = (props as any).err;

    useProgressBar();
    useAnalytics(); //Google Analytics + Hotjar tracking.

    return (
        <AuthStateApp>
            <Component {...pageProps} err={err} />
        </AuthStateApp>
    );
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext: AppContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);

//   return { ...appProps }
// }

export default MyApp;
