//Library
import { useEffect } from 'react';
import { Router } from 'next/router';
import nprogress from 'nprogress';

//Binding events for nprogress
nprogress.configure({
    showSpinner: false,
    template:
        '<div class="bar bg-safety-orange fixed top-0 w-full left-0 h-1" style="z-index:100" role="bar"><div class="peg"></div></div><div class="spinner" role="spinner"><div class="spinner-icon"></div></div>'
});

/** Add a progress bar on the top of the page. */
const useProgressBar = (): void => {
    useEffect(() => {
        Router.events.on('routeChangeStart', () => nprogress.start());
        Router.events.on('routeChangeComplete', () => nprogress.done());
        Router.events.on('routeChangeError', () => nprogress.done());
    }, []);
};

export default useProgressBar;
