//type
import {
    ForgotPasswordCodeFormProps,
    ForgotPasswordCodeInput,
    ForgotPasswordCodeFn
} from '@interfaces/auth/helpers/ForgotPasswordCodeForm';
import { AuthState } from '@aws-amplify/ui-components';

//library
import React, { ReactElement } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Auth } from 'aws-amplify';

/**
 * Create the first step for the forget form
 *
 * @param {ForgotPasswordCodeFormProps} props - The error, onSubmit and handleAuthStateChange props.
 * @returns {ReactElement} - The eamil form to get a code to chance password when we forgot it.
 */
const ForgotPasswordCodeForm: React.FC<ForgotPasswordCodeFormProps> = ({
    error,
    onSubmit,
    handleAuthStateChange
}: ForgotPasswordCodeFormProps): ReactElement => {
    const initialValues = {
        username: ''
    };

    /**
     * Submit the email to send the ForgotPassword code from cognito to the user email.
     *
     * @param {ForgotPasswordCodeInput} values - The email
     */
    const forgotPasswordCode: ForgotPasswordCodeFn = async (values: ForgotPasswordCodeInput) => {
        try {
            onSubmit();
            values.username = values.username.trim().toLowerCase();
            await Auth.forgotPassword(values.username);
            onSubmit(values);
        } catch (error) {
            switch (error.code) {
                case 'LimitExceededException':
                    onSubmit(undefined, error.message);
                    break;
                default:
                    onSubmit(undefined, 'An error occurred. Please try again');
            }
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
                username: Yup.string().email('Invalid email address').required('Required')
            })}
            onSubmit={async (values, { setSubmitting }) => {
                forgotPasswordCode({ ...values, state: 'code' });
                setSubmitting(false);
            }}>
            <Form>
                <div className="bg-white p-12 shadow-md rounded">
                    <div
                        className={`text-red-500 italic text-center py-5 ${
                            error ? 'block' : 'hidden'
                        }`}>
                        {error}
                    </div>
                    <div>
                        <label
                            className="block text-navy text-base font-medium leading-6"
                            htmlFor="username">
                            Email
                        </label>
                        <Field
                            className="shadow appearance-none border rounded w-full h-12 mt-1 pl-4 py-3 font-normal leading-tight focus:outline-none focus:shadow-outline"
                            name="username"
                            aria-label="username"
                            type="email"
                            placeholder="Email"
                            autoComplete="username"
                        />
                        <div className="text-red-500 text-xs italic absolute">
                            <ErrorMessage name="username" />
                        </div>
                    </div>
                </div>
                <div className="flex py-10 justify-between px-2 md:px-0">
                    <button
                        className="w-36 h-12 px-3  bg-white text-elements text-base font-medium rounded leading-tight hover:shadow-outline focus:shadow-outline border-solid border-2"
                        onClick={() => handleAuthStateChange(AuthState.SignIn)}
                        type="button">
                        Back
                    </button>

                    <button
                        className="w-36 h-12 px-3 bg-elements text-white text-base font-medium  rounded focus:outline-none leading-tight hover:shadow-outline focus:shadow-outline "
                        type="submit">
                        Send Code
                    </button>
                </div>
            </Form>
        </Formik>
    );
};

export default ForgotPasswordCodeForm;
