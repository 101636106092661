//types
import { RedirectProps } from '@interfaces/auth/helpers/Redirect';

//library
import React, { ReactElement } from 'react';
import Image from 'next/image';
import { Auth } from 'aws-amplify';
import { CognitoUserInterface } from '@aws-amplify/ui-components';

/**
 * Create a Redirect button.
 *
 * @param {RedirectProps} props - Message and url to redirect to.
 * @returns {ReactElement} - The sign out button.
 */
const Redirect: React.FC<RedirectProps> = ({
    children,
    authData,
    onClick
}: RedirectProps): ReactElement => {
    /** Redirect the user. */
    const redirect = async () => {
        onClick();
        await Auth.signOut();
        window.location.href = 'https://app.getsolar.ai';
    };

    /**
     * Check the authData for whether the user can access the app.
     *
     * @param {CognitoUserInterface} authData - The user data.
     * @returns {boolean} - Whether user has access or not.
     */
    const hasAccess = (authData: CognitoUserInterface): boolean => {
        const allowedGroups = (process.env.NEXT_PUBLIC_ALLOWED_GROUPS &&
            process.env.NEXT_PUBLIC_ALLOWED_GROUPS.split(',')) || ['admin'];
        return authData.signInUserSession.accessToken.payload[
            'cognito:groups'
        ].some((group: string) => allowedGroups.includes(group));
    };

    return (
        <>
            {hasAccess(authData) ? (
                children
            ) : (
                <div
                    aria-label="redirect"
                    className="flex flex-col h-screen w-full px-4 items-center justify-center">
                    <Image src="/solarai_logo.png" alt="Solar Ai" width="240" height="39" />
                    <p className="text-lg md:text-xl lg:text-2xl text-center mt-4 text-elements">
                        You do not have access to this website.
                    </p>
                    <button
                        aria-label="redirect-button"
                        onClick={redirect}
                        className="rounded-md px-4 py-2 mt-3 text-sm leading-5 text-gray-200 bg-elements hover:bg-navy focus:outline-none focus:bg-azure transition duration-150 ease-in-out">
                        You may want to go to app.getsolar.ai instead
                    </button>
                </div>
            )}
        </>
    );
};

export default Redirect;
