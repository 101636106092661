//type
import {
    ForgotPasswordFormsProps,
    ForgotPasswordFn,
    ForgotPasswordCodeInput,
    ForgotPasswordChangePasswordInput
} from '@interfaces/auth/helpers/ForgotPasswordForms';
import { AuthState } from '@aws-amplify/ui-components';

//library
import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';

//code
import Header from './Header';
import ForgotPasswordCodeForm from '@components/auth/helpers/ForgotPasswordCodeForm';
import ForgotPasswordChangePasswordForm from '@components/auth/helpers/ForgotPasswordChangePasswordForm';

/**
 * Create the forget password form step.
 *
 * @param {React.FC<ProfileFormProps>} props - The handleAuthStateChange function in the props.
 * @returns {ReactElement}
 */
const ForgotPasswordForms: React.FC<ForgotPasswordFormsProps> = ({
    handleAuthStateChange
}: ForgotPasswordFormsProps): ReactElement => {
    const [step, setStep] = useState<number>(0);
    const [username, setUsername] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    /**
     * Set the differents step of the forgot password.
     *
     * @param {Dispatch<SetStateAction<number>>} setStep - The step setter
     * @param {ForgotPasswordCodeInput | ForgotPasswordChangePasswordInput} values - The value of
     *     the step. We use it to chage betwee step 1 to 2
     * @param {string} error - The error if one of the cognito cal lsend one back
     */
    const forgotPassword: ForgotPasswordFn = async (
        setStep: Dispatch<SetStateAction<number>>,
        values?: ForgotPasswordCodeInput | ForgotPasswordChangePasswordInput,
        error?: string
    ) => {
        setError(null);

        if (values?.state == 'code') {
            handleAuthStateChange(AuthState.Loading);
            setUsername(values.username);
            setStep(1);
            handleAuthStateChange(AuthState.ForgotPassword);
        }

        if (error) {
            setError(error);
        }
    };

    return (
        <div slot="forgot-password" className="flex justify-center w-full">
            <div className="divide-y-0 md:w-2/3 lg:w-1/2 mt-24 w-full max-w-2xl">
                <Header sub_title="Forgot Password" />
                <div className="pt-12">
                    {Boolean(!step) && (
                        <ForgotPasswordCodeForm
                            error={error}
                            onSubmit={(values, error) => forgotPassword(setStep, values, error)}
                            handleAuthStateChange={handleAuthStateChange}
                        />
                    )}
                    {Boolean(step) && !!username && (
                        <ForgotPasswordChangePasswordForm
                            error={error}
                            username={username}
                            onSubmit={(values, error) => forgotPassword(setStep, values, error)}
                            handleAuthStateChange={handleAuthStateChange}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default ForgotPasswordForms;
