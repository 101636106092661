//type
import { GTagEvent } from '@interfaces/helpers/googleAnalytics';

/**
 * Google analytic function to send the change of page:  https://developers.google.com/analytics/devguides/collection/gtagjs/pages
 * @param {URL} url - the url to send to ga
 */
export const pageview = (url: URL): void => {
    if (!process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS) {
        return;
    }
    window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
        page_path: url
    });
};

/**
 * Google analytic function ot send an event:  // https://developers.google.com/analytics/devguides/collection/gtagjs/events
 * @param {GTagEvent} event - the event to send
 */
export const event = ({ action, category, label, value }: GTagEvent): void => {
    if (!process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS) {
        return;
    }
    window.gtag('event', action, {
        event_category: category,
        event_label: label,
        value: value
    });
};
