//type
//no type

//Library
import { useEffect } from 'react';
import { Router } from 'next/router';
import { hotjar } from 'react-hotjar';

//code
import * as googleAnalytics from './googleAnalytics';

/** Send page view to google analytics. */
const useAnalytics = (): void => {
    useEffect(() => {
        //hotjar
        if (process.env.NEXT_PUBLIC_HOTJAR_ID) {
            hotjar.initialize(Number(process.env.NEXT_PUBLIC_HOTJAR_ID), 6);
        }

        //google analytics
        const handleRouteChange = (url: URL) => {
            googleAnalytics.pageview(url);
        };

        Router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            Router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, []);
};

export default useAnalytics;
