//library
import React, { ReactElement } from 'react';
import Image from 'next/image';

interface HeaderProps {
    sub_title?: string;
}

/**
 * The Auth Header.
 *
 * @param {HeaderProps} props - The sub title for the header
 * @returns {ReactElement} - The header for the auth funnels
 */
const Header: React.FC<HeaderProps> = ({ sub_title = ' ' }: HeaderProps): ReactElement => (
    <>
        <div className="flex justify-center flex-col">
            <div className="m-auto">
                <Image src="/solarai_logo.png" alt="Solar Ai" width={245} height={39} />
            </div>
            <span className="text-gray-500 font-medium text-lg text-center">{sub_title}</span>
        </div>
    </>
);

export default Header;
